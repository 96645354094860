// ** React Imports
import { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';

// ** Axios Configuration
import axios from 'axios';
import alert from 'sweetalert2';
import 'animate.css/animate.css';
import '@styles/base/plugins/extensions/ext-component-sweet-alerts.scss';
import 'filepond/dist/filepond.min.css';

// axios.defaults.baseURL = 'http://192.168.1.13:8000/api';
// axios.defaults.baseURL = 'http://192.168.1.13:8000/api';
// axios.defaults.baseURL = 'http://anobaniapi.2.vistadev.ir/public/api'
// axios.defaults.baseURL = 'http://anobaniapi-test.2.vistadev.ir/public/api'
// axios.defaults.baseURL = 'http://192.168.146.84:8000/api'
axios.defaults.baseURL = 'https://api.anubani.com/api';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.common[
  'Authorization'
] = `Bearer ${sessionStorage.getItem('token')}`;
axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    if (error.response.status === 401) {
      sessionStorage.clear();
      localStorage.clear();
      window.location.replace('/login');
    }
    if (error.response.status === 404) {
      alert
        .fire({
          title: '404',
          text: 'صفحه مورد مورد نظر شما یافت نشد!',
          icon: 'error',
          showConfirmButton: true,
          confirmButtonText: 'بازگشت به صفحه نخست',
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            window.location.replace('/dashboard');
          }
        });
    }
    if (error.response.status === 422) {
      const { errors } = error.response.data;

      let Message = () => (
        <>
          <h6 style={{ marginBottom: 4 }}>اطلاعات ناقص است</h6>
          {Object.entries(errors).map(([, value], i) => (
            <p style={{ marginBottom: 0 }}>
              {i + 1}- {value[0]}
            </p>
          ))}
        </>
      );
      toast.error(<Message />);
    }
    if (error.response.status === 500) {
      alert
        .fire({
          title: 'خطای سرور!',
          text: 'خطایی در سرور رخ داده است ،مجدد امتحان کنید',
          icon: 'error',
          showConfirmButton: true,
          confirmButtonText: 'تازه سازی',
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
    }

    return Promise.reject(error);
  }
);

// ** Floara Imports
// Require Editor JS files.
// import 'froala-editor/js/froala_editor.pkgd.min.js'
// // import './froala_kg'
//
// // Require Editor CSS files.
// import 'froala-editor/css/froala_style.min.css'
// import 'froala-editor/css/froala_editor.pkgd.min.css'

// Include special components if required.
// import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
// import FroalaEditorA from 'react-froala-wysiwyg/FroalaEditorA';
// import FroalaEditorButton from 'react-froala-wysiwyg/FroalaEditorButton';
// import FroalaEditorImg from 'react-froala-wysiwyg/FroalaEditorImg';
// import FroalaEditorInput from 'react-froala-wysiwyg/FroalaEditorInput';

// ** Redux Imports
import { Provider } from 'react-redux';
import { store } from './redux/storeConfig/store';

// ** Intl, CASL & ThemeColors Context
import ability from './configs/acl/ability';
import { toast, ToastContainer } from 'react-toastify';
import { AbilityContext } from './utility/context/Can';
import { ThemeContext } from './utility/context/ThemeColors';
import { IntlProviderWrapper } from './utility/context/Internationalization';

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner';

// ** Ripple Button
import './@core/components/ripple-button';

// ** Fake Database
import './@fake-db';

// ** PrismJS
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-jsx.min';

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css';

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss';

// ** Core styles
import './@core/assets/fonts/Font-Yekan.css';
import './@core/scss/core.scss';
import './assets/scss/style.scss';

// ** Service Worker
import * as serviceWorker from './serviceWorker';
import { Alert } from 'reactstrap';

// ** Lazy load app
const LazyApp = lazy(() => import('./App'));

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <AbilityContext.Provider value={ability}>
        <ThemeContext>
          <IntlProviderWrapper>
            <LazyApp />
            <ToastContainer newestOnTop />
          </IntlProviderWrapper>
        </ThemeContext>
      </AbilityContext.Provider>
    </Suspense>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
